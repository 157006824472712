import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import getAxios from 'src/components/axios/Axios'
import request from 'src/components/request/request'
import {useDispatch} from "react-redux"
function TicketSummary() {
  const [carLoanData, setCarLoanData] = useState([])
  const [homeLoanData, setHomeLoanData] = useState([])
  const navigate = useNavigate();
  const dispatch=useDispatch()
  useEffect(() => {
    document.title="INRPLUS:Case Summary"
    dispatch({ type: 'loader', loader:true })
    fetchData()
  }, [])
  const fetchData = async () => {
    try {
      
    const res = await getAxios(request.ticketSummary)
    setCarLoanData(res?.data?.records?.carloan)
    setHomeLoanData(res?.data?.records?.homeloan)
    dispatch({ type: 'loader', loader:false })
    } catch (error) {
      dispatch({ type: 'loader', loader:false })
    }
  }
  const getToList = (status, queueId) => {
    console.log("navigate")
    navigate('/base/ticket-list', {
      state: {
        status: status,
        queue_id: queueId
      }
    });
  }
  const statusList = (status) => {
    navigate('/base/ticket-list', {
      state: {
        status: status,
      }
    });
  }
  const queueList = (queueId) => {
    console.log("queue id", queueId)
    navigate('/base/ticket-list', {
      state: {
        queue_id: queueId,
      }
    });
  }
  return (
    <div className='ticket-summary'>
      <div className='text-center pt-3'>
        <h5>Car Loan</h5>
      </div>
      <table className="table table-striped ">
        <thead>
          <tr>
            <th scope="col">
            </th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("new")}>new</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("allocate")}>allocate</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("chklst")}>chklst</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("kyc")}>kyc</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("finan")}>finan</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("bank")}>bank</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("loans")}>loans</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("legaldoc")}>legaldoc</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("login")}>login</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("ov")}>ov</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("rv")}>rv</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("tech")}>tech</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("legal")}>legal</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("cpd")}>cpd</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("sanc")}>sanc</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("sanccnd")}>sanccnd</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("disbursal_login")}>disbursal_login</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("disbursed")}>disbursed</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("ne")}>ne</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("ni")}>ni</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("rejct")}>rejct</th>
          </tr>
        </thead>
        <tbody>
          {carLoanData?.map((value, index) => (
            <tr key={index}>
              <th className='cursor-pointer' scope="row" style={{ width: "20%" }} onClick={() => queueList(value.queue_id)}>{value.queue_name}</th>
              {value.statuses.map((val1, index) => (
                <th key={index} className='cursor-pointer' onClick={() => getToList(Object.keys(val1)[0], value.queue_id)}>{val1[Object.keys(val1)] == 0 ? "-" : val1[Object.keys(val1)]}</th>
              ))}

            </tr>
          ))}
        </tbody>
      </table>
      <div className='text-center pt-3'>
        <h5>Home Loan</h5>
      </div>
      <table className="table table-striped ">
        <thead>
          <tr>
            <th scope="col">
            </th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("new")}>new</th>
            <th scope="col" className="cursor-pointer" onClick={() => statusList("allocate")}>allocate</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("chklst")}>chklst</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("kyc")}>kyc</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("finan")}>finan</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("bank")}>bank</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("loans")}>loans</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("legaldoc")}>legaldoc</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("login")}>login</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("ov")}>ov</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("rv")}>rv</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("tech")}>tech</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("legal")}>legal</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("cpd")}>cpd</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("sanc")}>sanc</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("sanccnd")}>sanccnd</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("disbursal_login")}>disbursal_login</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("disbursed")}>disbursed</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("ne")}>ne</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("ni")}>ni</th>
            <th scope="col" className='cursor-pointer' onClick={() => statusList("rejct")}>rejct</th>
          </tr>
        </thead>
        <tbody>
          {homeLoanData?.map((value, index) => (
            <tr key={index}>
              <th className='cursor-pointer' scope="row" style={{ width: "20%" }} onClick={() => queueList(value.queue_id)}>{value.queue_name}</th>
              {value.statuses.map((val1, index) => (
                <th key={index} className='cursor-pointer' onClick={() => getToList(Object.keys(val1)[0], value.queue_id)}>{val1[Object.keys(val1)] == 0 ? "-" : val1[Object.keys(val1)]}</th>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TicketSummary