import axios from 'axios'
import React, { Component, Suspense, useEffect, useState } from 'react'
import { HashRouter,BrowserRouter, Route, Routes } from 'react-router-dom'
import request from './components/request/request'
import { useNavigate } from 'react-router-dom'
import './scss/style.scss'
import getAxios from './components/axios/Axios'
import { Navigate } from 'react-router-dom'
import ReactDOM from 'react-dom';
import "./App.scss"
import { ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'
import UserProvider from './components/context/UserProvider'
import TicketSummary from './views/base/ticket-summary/TicketSummary'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const TrackLoan=React.lazy(()=>import("./views/pages/track-loan/TrackLoan"))
const Whatsapp=React.lazy(()=>import("./views/pages/whatsapp/Whatsapp"))

function App() {
  const [loginStatus, setLoginStatus] = useState(false)

  const loginCheck = async () => {

    const res = await getAxios(request.who_ami)
    if (res?.data?.apiresponse?.type == "OK") {
      setLoginStatus(true)
     // console.log("Res Data",res?.data?.record.showLog)
    if(res?.data?.record?.showLog == 1){
     console.log("App.js if condition")
    
    }else{
      console.log("App.js else if condition")
      setTimeout(()=>{
        document.getElementById('actl_menu').style.display = "none"
      }, 300)
    }
    
    } else {
      setLoginStatus(false)
      
    }
  }
  useEffect(() => {
    
      loginCheck()
   
  }, [])
  const loaderShow = useSelector((state) => state.loader)

  return (
    <HashRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
      />
      {loaderShow &&
        <div className='loaderApp'>
          <button className="btn btn-primary" type="button" >
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
        </div>}
      <div className='' style={{ opacity: loaderShow ? 0.5 : 1 }}>
      
        <Suspense fallback={loading}>
          <Routes fallback={loading}>
            <Route exact path="/register" fallback={loading} name="Register Page" element={<Register />} />
            <Route exact path="/404" fallback={loading} name="Page 404" element={<Page404 />} />
            <Route exact path="/500" fallback={loading} name="Page 500" element={<Page500 />} />
            <Route exact path="/login" fallback={loading} name="Login Page" element={<Login />} />
            <Route exact path="/trackmyloan/:id/:signature" fallback={loading} name="Login Page" element={<TrackLoan />} />
            <Route exact path="/startchat/:id/:signature" fallback={loading} name="Login Page" element={<Whatsapp />} />
            {loginStatus && <Route path="*" name="Home" fallback={loading} element={<DefaultLayout />} />}
            {!loginStatus && <Route path="*" name="Login Page" fallback={loading} element={<Login />} />}
          </Routes>
        </Suspense>
      </div>
    </HashRouter>
  )
}

export default App
