// const BASE_URL="http://learn2code.redgrape.tech:8000/"
const BASE_URL="https://backend.inrplus.in/"
const request={
    login_user:BASE_URL+"backend/verify_user",
    who_ami:BASE_URL+"backend/whoami",
    log_out:BASE_URL+"backend/logoff",
    ticketList:BASE_URL+"backend/list_tickets",
  
    ticketSummary:BASE_URL+"backend/get_ticket_summery",
    ticketDetails:BASE_URL+"backend/get_ticket_details",
    qr_list:BASE_URL+"api/v1/admin/list_qrcodes",
    generate_qr:BASE_URL+"api/v1/admin/generate_qrcodes",
    exportQrCode:BASE_URL+"api/v1/admin/export_qrcodes",
    productList:BASE_URL+"api/v1/admin/list_products",
    batchList:BASE_URL+"api/v1/admin/list_batch",
    loanStatus:BASE_URL+"trackmyloan",
    activityLog:BASE_URL+"get_case_updates"
}
export default request