import axios from "axios"
const instance = axios.create({
    withCredentials: true,
    headers:{
        "Access-Control-Allow-Origin": "*",
        'Content-Type':'application/json'
    }
})
// For The activity Log Components
const instancetwo = axios.create({
    withCredentials: true,
    baseURL:"https://backend.inrplus.in/",
    headers:{
        'Content-Type':'application/json'
    }
  })
// For The activity Log Components
instance.defaults.withCredentials = true;
const getAxios = async (url, data) => {
    try {
        const res = await instance.get(url, data)
        // console.log("res",res)
        return res
    } catch (err) {
console.log("Could'nt get the data", err)
    }
}
const postAxios = async (url, data) => {
    try {
        const res = await instance.post(url,data)
        return res
    } catch (err) {

    }
}

export default getAxios;
export { postAxios}